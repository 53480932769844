import React from 'react';
import SignupEmail from 'views/SignupEmail';
import Minimal from 'layouts/Minimal';
import WithLayout from 'WithLayout';

const SignupEmailPage = (): JSX.Element => {
  return <WithLayout component={SignupEmail} layout={Minimal} />;
};

export default SignupEmailPage;
