import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, TextField } from '@material-ui/core';
import validate from 'validate.js';
import { LearnMoreLink } from 'components/atoms';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 300,
    },
  },
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 120,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 120,
    },
  },
  sdg: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 300,
    },
  },
  industry: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 300,
    },
  },
  companyWebsite: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 300,
    },
  },
};

const Form = (): JSX.Element => {
  const classes = useStyles();

  const [serverResponse, setServerResponse] = React.useState(``);

  const [formState, setFormState] = React.useState<FormStateProps>({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
    setServerResponse(``);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formState.isValid) {
      const response = await window.fetch(`/api/sign-up-form`, {
        method: `POST`,
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(formState.values),
      });
      setServerResponse(response);

      //window.location.replace('/');
    }

    setFormState(formState => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field: string): boolean =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <form name="password-reset-form" method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              placeholder="First name"
              label="First name *"
              variant="outlined"
              size="medium"
              name="firstName"
              fullWidth
              helperText={
                hasError('firstName') ? formState.errors.firstName[0] : null
              }
              error={hasError('firstName')}
              onChange={handleChange}
              type="firstName"
              value={formState.values.firstName || ''}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              placeholder="Last name"
              label="Last name *"
              variant="outlined"
              size="medium"
              name="lastName"
              fullWidth
              helperText={
                hasError('lastName') ? formState.errors.lastName[0] : null
              }
              error={hasError('lastName')}
              onChange={handleChange}
              type="lastName"
              value={formState.values.lastName || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="E-mail"
              label="E-mail *"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              helperText={hasError('email') ? formState.errors.email[0] : null}
              error={hasError('email')}
              onChange={handleChange}
              type="email"
              value={formState.values.email || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="Your goals"
              label="Development goals you are interested in"
              variant="outlined"
              size="medium"
              name="sdg"
              fullWidth
              helperText={hasError('sdg') ? formState.errors.sdg[0] : null}
              error={hasError('sdg')}
              onChange={handleChange}
              type="sdg"
              value={formState.values.sdg || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="Your industries"
              label="Industries you are interested in"
              variant="outlined"
              size="medium"
              name="industry"
              fullWidth
              helperText={
                hasError('industry') ? formState.errors.industry[0] : null
              }
              error={hasError('industry')}
              onChange={handleChange}
              type="industry"
              value={formState.values.industry || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="Company website"
              label="Website *"
              variant="outlined"
              size="medium"
              name="companyWebsite"
              fullWidth
              helperText={
                hasError('companyWebsite')
                  ? formState.errors.companyWebsite[0]
                  : null
              }
              error={hasError('companyWebsite')}
              onChange={handleChange}
              type="companyWebsite"
              value={formState.values.companyWebsite || ''}
            />
          </Grid>
          <Grid item xs={12}>
            <i>
              <Typography variant="subtitle2">
                Fields that are marked with * sign are required.
              </Typography>
            </i>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
            >
              Subscribe to the newsleter
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Form;
